.toggle,
.toggle--selected {
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.toggle--selected {
  background-color: white;
  border-radius: 30px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.toggle-drawer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.toggle__button--pink,
.toggle__button--yellow,
.toggle__button--green,
.toggle__button--pink-selected,
.toggle__button--yellow-selected,
.toggle__button--green-selected {
  border-radius: 50%;
  width: 45px;
  height: 45px;
  text-decoration: none;
  margin: 5px;
  border: 0;
}

.toggle__button--pink-selected,
.toggle__button--yellow-selected,
.toggle__button--green-selected {
  border: 5px solid white;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  width: 50px;
  height: 50px;
}

.toggle__button--pink,
.toggle__button--pink-selected {
  background-color: #fc9d9a;
}

.toggle__button--yellow,
.toggle__button--yellow-selected {
  background-color: #f9cdad;
}

.toggle__button--green,
.toggle__button--green-selected {
  background-color: #83af9b;
}
