.App {
  padding: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: top;
  flex-direction: column;
  min-height: 100vh;
}

.pink-bg {
  background-color: #fc9d9a;
}

.yellow-bg {
  background-color: #f9cdad;
}

.green-bg {
  background-color: #83af9b;
}
