.profile {
  max-width: 700px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.profile__content {
  background: white;
  padding: 50px;
}

.profile__icon {
  margin-bottom: -70px;
  width: 150px;
  height: 150px;
  background-image: url('../../images/pp.jpeg');
  background-position: 60% 45%;
  background-size: 160%;
  border: 7px solid rgb(255, 255, 255);
  border-radius: 50%;
  background-color: white;
  z-index: 1;
}

.profile-content__header {
  letter-spacing: 7px;
  padding-bottom: 0;
  margin-bottom: 0;
  font-size: 40px;
}

.profile-content__subheader {
  margin-top: 0;
  padding-top: 0;
  line-height: 80%;
  color: DarkSlateGray;
}

.profile-content__emoji-divider {
  letter-spacing: 10px;
}

.profile-content__paragraph {
  margin: 30px;
}

@media screen and (max-width: 500px) {
  .profile-content__emoji-divider {
    letter-spacing: 10px;
    line-height: 300%;
  }

  .profile-content__paragraph {
    display: none;
  }
}

.profile-content__accent--pink {
  font-weight: 700;
  color: #c2635f;
}

.profile-content__accent--yellow {
  font-weight: 700;
  color: #c7936e;
}

.profile-content__accent--green {
  font-weight: 700;
  color: #558771;
}

.profile-content__links {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.profile-link,
.profile-link:active,
.profile-link:visited {
  font-size: 20px;
  text-decoration: none;
  font-weight: 500;
  padding: 10px;
  background-color: #f0f0f0;
  width: 200px;
  color: black;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin: 5px;
}

.profile-link:hover,
.profile-link:visited:hover {
  font-weight: 900;
  letter-spacing: 2px;
  color: #222;
}

.profile-link__pink:hover,
.profile-link__pink:visited:hover {
  background-color: #fc9d9a;
  border-bottom: 3px solid #e37874;
}

.profile-link__green:hover,
.profile-link__green:visited:hover {
  background-color: #83af9b;
  border-bottom: 3px solid #669b83;
}

.profile-link__yellow:hover,
.profile-link__yellow:visited:hover {
  background-color: #f9cdad;
  border-bottom: 3px solid #dfa983;
}
